.App {
  /* text-align: center; */
  direction: rtl;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.thumbnail {
  width: 90px;
  margin-inline-end: 5px;
}

.cover-thumbnail {
  width: 150px;
  /* margin-inline-end: 5px; */
}

td {
  text-align: start;
}

.header-icon {
  color: grey;
  margin-inline-end: 10px;
}

.page-header {
  position: relative;
}

.page-header h1 {
  display: inline-block;
  text-align: start;
  font-size: 1.2em;
  margin-top: 0.7em;
  margin-bottom: 1.7em;
}

.page-header DIV {
  display: flex;
  position: absolute;
  margin: 0px auto;
  left: 10px;
  top: 10px;
  bottom: 10px;
}

.quantity-column {
  text-align: center;
}

.action-bar {
  text-align: end;
  padding-bottom: 10px;
}

.action-button {
  min-width: 80px;
  padding: 0px 30px;
}

.green-button {
  background-color: green;
  color: white;
  border: solid 1px darkGreen;
}
.red-button {
  background-color: red;
  color: white;
  border: solid 1px darkRed;
}

.action-icon {
  cursor: pointer;
  margin-inline-end: 10px;
}

.red {
  color: red;
}

.action-widget {
  display: inline-block;
  margin-inline-end: 10px;
}

.action-widget A {
  color: black;
}

/* input:invalid {
  border: 2px dashed red;
} */

.cover-preview {
  max-width: 120px;
  /* position: absolute; */
  /* left: 10px; */
  /* margin-top: -120px; */
}

.cover-pane {
  width: 150px;
  text-align: center;
}

.menu {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  margin-top: 20px;
  background-color: #f0f0f0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 150px;
}

.menu a {
  display: block;
  text-decoration: none;
  min-height: 30px;
  color: black;
  margin-top: 5px;
}


.menu a:hover {
  color: blue;
}

.round-rect-border {
  border: solid 1px lightgray;
  border-radius: 20px;
}

.modal-header .btn-close {
  padding: 1rem 1rem !important;
  margin: -1rem auto -1rem -1rem !important;
}

.wrapper {
  position:relative;
}

.wrapper input {
  padding-inline-start: 40px;
}

.icon {
  height: 2.3rem;
  width: 1.9rem;
  background-color: lightgray;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 73%;
  right: 2px;
  transform: translateY(-50%);
  text-align: center;
}

.enara-navbar {
  background-color: black;
  color: white;
}

.enara-navbar a {
  color: white;
}

.enara-navbar a:hover {
  color: rgb(13, 110, 253);
}

.enara-navbar span {
  color: white;
}

.icon-button {
  margin-inline-end: 15px !important;
  padding: 0px !important;
  /* margin: 0px !important; */
  color: black !important;
}