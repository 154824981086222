.sl-widget {
    position: relative;
    /* display: inline-block; */
    /* padding-inline-start: 5px;
    padding-inline-end: 20px; */
    margin-inline-end: 10px;
    /* border: solid 1px #808080; */
    border-radius: 5px;
    padding: 6px 6px;
}

.sl-container {
    position: relative;
}

.sl-remove {
    position: absolute;
    left: 0px;
    top: 10px;
    margin-left: 20px;
    color: red;
}

.sl-add {
    position: absolute;
    top: -25px;
    left: 10px;
}

.sl-close { 
    color: red;
    position: absolute;
    left: 0px;
}